<template>
  <div class="fade">
    <div class="header">
      <div class="navLeft">
        <!-- <img id="mmenu_logo"
        alt="Periodum Logo" src="../resources/img/periodum.svg" height="40">  -->
        <!-- Generator: Adobe Illustrator 24.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 600 85"
          id="mmenu_logo"
          height="40"
          style="enable-background: new 0 0 600 85"
          xml:space="preserve"
        >
          <g id="LOGO">
            <router-link to="/">
              <rect x="0" class="eaLogoBackPart" width="330" height="85" />
              <g>
                <path
                  class="periodumLogoPart"
                  d="M30.1,23c-2.7-1.7-5.8-2.4-9.1-2.4c-2.2,0-4.3,0.4-6.2,1.1s-3.7,1.9-5.3,3.3l-2,1.9v-6.3H1.3V74h6.2V51.4l2,2
                c1.6,1.5,3.3,2.6,5.3,3.4s4,1.1,6.3,1.1c3.3,0,6.4-0.8,9.2-2.5c2.7-1.7,4.9-3.9,6.3-6.8c1.6-2.9,2.3-6.1,2.3-9.6
                c0-3.3-0.8-6.3-2.3-9.1C35.1,26.8,32.8,24.6,30.1,23z M31,45.8c-1.1,2.1-2.6,3.6-4.6,4.8c-1.9,1.1-4,1.8-6.4,1.8
                c-2.5,0-4.7-0.6-6.5-1.8c-2-1-3.5-2.7-4.6-4.8c-1-2-1.6-4.4-1.6-6.8c0-2.2,0.5-4.3,1.6-6.3c1-2.1,2.6-3.7,4.5-4.8
                c3.6-2.1,8.8-2.3,13,0c2,1,3.5,2.6,4.6,4.7c1,2,1.6,4.2,1.6,6.4C32.5,41.5,32,43.9,31,45.8z"
                />
                <path
                  class="periodumLogoPart"
                  d="M73,22.9c-2.6-1.6-5.8-2.3-9.4-2.3c-3.4,0-6.6,0.8-9.4,2.5c-2.9,1.7-5.2,3.9-6.8,6.7
                c-1.7,2.8-2.5,5.9-2.5,9.2c0,3.5,0.8,6.6,2.5,9.5c1.7,2.8,3.9,5.2,7,6.8s6.3,2.5,10,2.5c3.8,0,7.4-0.9,10.5-2.8
                c2.8-1.7,5-3.8,6.5-6.6l-4.8-2.6c-2.8,4.4-6.8,6.5-12,6.5c-2.6,0-4.9-0.5-7-1.6c-2.1-1-3.6-2.6-4.9-4.6c-0.9-1.7-1.5-3.3-1.6-4.9
                L51,39.9h31c0-0.3,0-0.7,0-1.1c0-3.5-0.8-6.7-2.5-9.5C78,26.6,75.7,24.5,73,22.9z M51.6,35.1l0.4-1.5c0.6-2.2,2.1-4,4.3-5.5
                c4.2-2.7,10.1-2.9,14.8-0.1c2.4,1.3,3.8,3.3,4.5,5.6l0.4,1.5H51.6z"
                />
                <path
                  class="periodumLogoPart"
                  d="M91.8,25.2c-2.4,2.7-3.6,7.1-3.7,12.9L87.9,58h6.3V38c0-4.2,0.6-7.1,2-8.7c1.3-1.8,3.7-2.7,7.1-2.7l4.7,0.1
                v-5.5c-0.8,0-2.3,0-4.7,0C98,21.1,94,22.5,91.8,25.2z"
                />
                <g>
                  <path
                    class="periodumLogoPart"
                    d="M117.1,7.7c-1,0-1.9,0.4-2.7,1.1l0,0c-0.8,0.7-1.1,1.7-1.1,2.7s0.4,2,1.1,2.8c1.6,1.6,3.8,1.6,5.5,0
                  c0.8-0.8,1.1-1.7,1.1-2.7s-0.4-2-1.1-2.7C119,8.1,118.1,7.7,117.1,7.7z"
                  />
                  <rect
                    x="114"
                    y="20.5"
                    class="periodumLogoPart"
                    width="6.3"
                    height="37.5"
                  />
                </g>
                <path
                  class="periodumLogoPart"
                  d="M189.2,20.5c-3.3,0-6.3,0.8-9,2.5s-4.9,3.9-6.5,6.8c-1.6,2.8-2.3,5.8-2.3,9c0,3.5,0.7,6.8,2.3,9.8
                c1.6,2.9,3.6,5.2,6.4,6.8c2.7,1.7,5.7,2.5,9.1,2.5c2.3,0,4.5-0.4,6.4-1.1c2-0.8,3.7-2,5.4-3.4l2-1.9V58h6.1V7.7H203v19.1l-2-1.8
                c-1.6-1.5-3.4-2.6-5.4-3.3C193.5,20.9,191.4,20.5,189.2,20.5z M201.3,32.6c1,1.9,1.6,4,1.6,6.3c0,2.6-0.5,4.9-1.6,6.8l0,0
                c-1,2-2.6,3.6-4.5,4.8c-1.8,1.1-4,1.8-6.5,1.8c-2.4,0-4.6-0.5-6.4-1.7c-2-1.1-3.4-2.7-4.5-4.8c-1-2-1.7-4.4-1.7-7
                c0-2.3,0.5-4.4,1.6-6.3s2.6-3.5,4.6-4.7c4-2.4,9.2-2.3,13-0.1C198.6,28.9,200.2,30.6,201.3,32.6z"
                />
                <path
                  class="periodumLogoPart"
                  d="M230.1,52.5c-3.3,0-5.7-0.9-7.1-2.7c-1.3-1.8-2-4.6-2-8.7V20.5h-6.2v21.4c0,5.7,1.2,9.9,3.5,12.3
                c2.4,2.5,6.3,3.7,11.7,3.7s9.3-1.2,11.7-3.7s3.5-6.6,3.5-12.3V20.5H239V41c0,2.8-0.2,5-0.7,6.5c-0.5,1.8-1.6,3-2.9,3.8
                C234.1,52.2,232.3,52.5,230.1,52.5z"
                />
                <path
                  class="periodumLogoPart"
                  d="M299,22c-3.6-2-8.9-2.1-13.1-0.2c-1.9,0.8-3.6,2.2-5,3.9l-0.9,1.1l-0.9-1.2c-1.1-1.6-2.7-2.8-4.7-3.7
                c-2-0.9-4.2-1.3-6.5-1.3c-3.2,0-6.2,1-8.7,2.9l-1.9,1.5v-4.3h-6.2v37.5h6.2V33.3c0-2.1,1-3.8,3.1-5.3c1.9-1.5,4.2-2.2,6.5-2.2
                c2.6,0,4.6,0.8,6.1,2.5s2.2,3.8,2.2,6.5v23h6.1V33.6c0-2.1,1-3.9,3-5.5c1.9-1.6,4-2.3,6.4-2.3c2.6,0,4.7,0.9,6.2,2.6
                s2.2,4,2.2,6.8v22.6h6.2V34c0-2.8-0.6-5.2-1.8-7.4C302.7,24.6,301.1,23,299,22z"
                />
                <path
                  class="periodumLogoPart"
                  d="M152.7,50.5c-3.9,2.3-9.6,2.3-13.8,0c-2.1-1.1-3.6-2.8-4.8-4.8c-0.8-1.6-1.3-3.2-1.6-5.1h-6.4
                c0.2,2.9,1,5.6,2.5,8c1.7,2.9,4.2,5.2,7.2,6.8c2.9,1.6,6.3,2.4,10,2.4c3.6,0,7.1-0.8,10.1-2.4s5.4-3.8,7.2-6.7
                c1.3-2.4,2.2-5.1,2.5-8.1h-6.3c-0.2,1.9-0.7,3.5-1.5,5.1l0,0C156.4,47.8,154.7,49.4,152.7,50.5z"
                />
                <g>
                  <path
                    class="periodumLogoPart"
                    d="M136.4,39.8c0,0,0.2,8.4,7.9,9.4c7.8,1,11.1-5.7,10.9-9.4c0,0-4.4-2.6-8.8,0S139.7,41.9,136.4,39.8z"
                  />
                  <circle
                    class="periodumLogoPart"
                    cx="146.7"
                    cy="32.8"
                    r="3.1"
                  />
                  <circle
                    class="periodumLogoPart"
                    cx="141.2"
                    cy="24.6"
                    r="2.2"
                  />
                  <circle class="periodumLogoPart" cx="145.7" cy="16.7" r="2" />
                </g>
              </g>
            </router-link>
            <rect
              x="333.3"
              y="12.8"
              class="eaLogoPart"
              width="1"
              height="59.1"
            />
          </g>
          <g id="EA">
            <g>
              <a href="https://evrimagaci.org/" target="_blank" rel="periodum">
                <rect x="335" class="eaLogoBackPart" width="265" height="85" />
              </a>
              <path
                class="eaLogoPart"
                d="M377.6,42.7c0,5.3-4.2,9.4-9.3,9.4c-3.5,0-6.3-1.8-7.8-4.6v4.2h-1.8V26.5h1.8v11.4c1.5-2.8,4.3-4.6,7.8-4.6
              C373.4,33.3,377.6,37.4,377.6,42.7z M375.8,42.7c0-4.3-3.4-7.7-7.6-7.7c-4.3,0-7.6,3.4-7.6,7.7s3.4,7.7,7.6,7.7
              S375.8,47,375.8,42.7z"
              />
              <path
                class="eaLogoPart"
                d="M396.3,33.7l-7.8,19.7c-1.4,3.5-4,5.8-7.1,5.6v-1.7c2.4,0.2,4.3-1.6,5.4-4.2l0.4-1l-8.3-18.4h1.9l7.3,16
              l6.3-16C394.4,33.7,396.3,33.7,396.3,33.7z"
              />
              <path
                class="eaLogoPart"
                d="M424.1,49.9v1.8h-14.5V26.5H424v1.8h-12.4v9.8H423v1.8h-11.5v10C411.5,49.9,424.1,49.9,424.1,49.9z"
              />
              <path
                class="eaLogoPart"
                d="M443.6,33.7l-7.6,18h-2.2l-7.6-18h1.9l6.8,16.2l6.8-16.2H443.6z"
              />
              <path
                class="eaLogoPart"
                d="M454.7,33.5v1.7c-3.1,0-6,1.9-6,6.5v10h-1.8v-18h1.8v3.5C449.9,34.4,452.2,33.5,454.7,33.5z"
              />
              <path
                class="eaLogoPart"
                d="M457.8,27.9c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4S457.8,28.7,457.8,27.9z M458.3,33.7h1.8
              v18h-1.8V33.7z"
              />
              <path
                class="eaLogoPart"
                d="M491.5,40.3v11.4h-1.8V40.3c0-3.4-2-5.3-4.8-5.3c-3,0-5.4,2-5.4,6.6v10.1h-1.8V40.3c0-3.4-1.8-5.3-4.6-5.3
              c-3,0-5.6,2-5.6,6.6v10.1h-1.8v-18h1.8v3.1c1.3-2.5,3.5-3.5,5.8-3.5c2.6,0,4.7,1.3,5.6,3.7c1.3-2.5,3.6-3.7,6-3.7
              C488.8,33.3,491.5,35.8,491.5,40.3z"
              />
              <path
                class="eaLogoPart"
                d="M521.1,45.3h-12.7l-2.5,6.4h-2l9.8-25.2h2l9.8,25.2h-2L521.1,45.3z M520.4,43.5l-5.6-14.7l-5.6,14.7H520.4z"
              />
              <path
                class="eaLogoPart"
                d="M546.4,33.7v17.2c0,5.4-4,8.5-9,8.5c-4.2,0-7.2-1.7-8.5-4.3l1.6-0.9c0.9,1.9,3,3.5,6.9,3.5
              c4.5,0,7.2-2.6,7.2-6.7v-3.3c-1.5,2.8-4.3,4.6-7.8,4.6c-5.2,0-9.3-4.1-9.3-9.4s4.2-9.4,9.3-9.4c3.5,0,6.3,1.8,7.8,4.6v-4.2h1.8
              V33.7z M544.6,42.7c0-4.3-3.4-7.7-7.6-7.7s-7.6,3.4-7.6,7.7s3.4,7.7,7.6,7.7S544.6,47,544.6,42.7z M532.3,27h1.7
              c0,1.9,1.2,2.8,3.1,2.8c1.9,0,3.1-0.9,3.1-2.8h1.7c0,2.7-1.9,4.4-4.8,4.4C534.3,31.4,532.3,29.7,532.3,27z"
              />
              <path
                class="eaLogoPart"
                d="M569.6,33.7v18h-1.8v-4.1c-1.5,2.7-4.3,4.5-7.8,4.5c-5.1,0-9.3-4.1-9.3-9.4s4.2-9.4,9.3-9.4
              c3.5,0,6.2,1.8,7.8,4.5v-4.1C567.8,33.7,569.6,33.7,569.6,33.7z M567.8,42.7c0-4.3-3.3-7.7-7.6-7.7s-7.6,3.4-7.6,7.7
              s3.4,7.7,7.6,7.7S567.8,47,567.8,42.7z"
              />
              <path
                class="eaLogoPart"
                d="M574,42.7c0-5.3,4-9.4,9.5-9.4c3.5,0,6.6,1.8,7.9,4.6l-1.5,0.9c-1-2.3-3.5-3.8-6.4-3.8
              c-4.4,0-7.6,3.4-7.6,7.7s3.3,7.7,7.6,7.7c2.9,0,5.3-1.5,6.5-3.8l1.5,0.9c-1.4,2.8-4.5,4.6-8,4.6C578,52.1,574,48.1,574,42.7z"
              />
              <path class="eaLogoPart" d="M595.4,33.7h1.8v18h-1.8V33.7z" />
            </g>
          </g>
        </svg>
      </div>

      <div class="navRight">
        <!-- <router-link to="/bilgi" id="mmenu_info" class="navIcon">
          <div class="las la-book"></div> {{ loc.pages.info.title }}
        </router-link> -->
        <router-link to="/hakkinda" id="mmenu_about" class="navIcon">
          <div class="las la-cube"></div>
          {{ loc.pages.about.title }}
        </router-link>
        <a
          :href="loc.pages.support.link_to"
          target="_blank"
          id="mmenu_support"
          class="navIcon"
        >
          <div class="las la-hand-holding-heart"></div>
          {{ loc.pages.support.title }}
        </a>

        <button
          @click="languageSwitch()"
          class="navIcon"
          id="mmenu_langSwitch"
        ></button>
      </div>
    </div>

    <router-view :loc="loc" v-if="!homepage" />
    <div v-if="homepage">
      <div id="listmode" class="fade">
        <ListDisplay
          :locale="loc"
          :elements="elements"
          @getElement="setElement"
        />
      </div>
      <div id="tablemode" class="fade">
        <TableDisplay
          :locale="loc"
          :elements="elements"
          @getElement="setElement"
        />
      </div>
    </div>

    <div @click="toggleModal" class="modal" id="element_modal">
      <div
        :class="{
          'modal fade active': modalViewable === true,
          'modal fade inactive': modalViewable === false,
        }"
      >
        <ElementModal
          v-if="modalViewable"
          :locale="localization"
          :element="currentElement"
          :image="elementImages[currentElement.number - 1] || null"
          :key="modalkey"
        />
      </div>
    </div>
  </div>

  <div v-if="visitedBefore() === 'false' && language === 'tr'"><TOUR /></div>

  <footer v-if="!homepage" class="noselect">
    <a
      href="https://dar.vin/eadiscord_periodum"
      target="_blank"
      id="discordInvitation"
      class="social"
    >
      <img :src="social.discord" alt="discord logosu" />
    </a>
    <a
      href="https://dar.vin/eatelegram_periodum"
      target="_blank"
      id="telegramInvitation"
      class="social"
    >
      <img :src="social.telegram" alt="telegram logosu" />
    </a>
    <a
      href="https://dar.vin/eapatreon_periodum"
      target="_blank"
      id="patreonInvitation"
      class="social"
    >
      <img :src="social.patreon" alt="patreon logosu" />
    </a>
    <!-- <a href="https://dar.vin/eakreosus_periodum" target="_blank" id="kreosusInvitation" class="social">
      <img :src="social.kreosus" alt="kreosus logosu">
    </a> -->
  </footer>
</template>

<script>
import ListDisplay from "@/components/ListModeDisplay.vue";
import TableDisplay from "@/components/TableModeDisplay.vue";
import ElementModal from "@/components/ElementModal.vue";
import TOUR from "@/addons/PageTour.vue";

import ELEMENT from "@/resources/elements_main.json";
import ELEMENT_IMAGES from "@/resources/elements_images.json";

const ELEMENT_LANGUAGE = require("../resources/locale/tr/elements_text.json");
const CONTENT_LANGUAGE = require("../resources/locale/tr/general.json");

const _ = require("lodash");
const objectsById = {};
// Store json1 objects by id.
for (const obj1 of ELEMENT) {
  objectsById[obj1.number] = obj1;
}

for (const obj2 of ELEMENT_LANGUAGE) {
  const id = obj2.number;

  if (objectsById[id]) {
    // Object already exists, need to merge.
    // Using lodash's merge because it works for deep properties, unlike object.assign.
    objectsById[id] = _.merge(objectsById[id], obj2);
  } else {
    // Object doesn't exist in merged, add it.
    objectsById[id] = obj2;
  }
}

// All objects have been merged or added. Convert our map to an array.
const ELEMENTS = Object.values(objectsById);
// TOUR
export default {
  components: { ListDisplay, TableDisplay, ElementModal, TOUR },
  name: "PeriodumPage",
  data() {
    return {
      elements: ELEMENTS,
      currentElement: ELEMENTS[0],
      elementImages: ELEMENT_IMAGES,
      loc: CONTENT_LANGUAGE,
      modalViewable: false,
      social: {
        discord: require("../resources/img/credits/discord.svg"),
        telegram: require("../resources/img/credits/telegram.svg"),
        patreon: require("../resources/img/credits/patreon.svg"),
        // kreosus: require("../resources/img/credits/kreosus.svg"),
      },
      locale: {
        tr: require("../resources/locale/tr/general.json"),
        en: require("../resources/locale/en/general.json"),
      },
      elementLanguage: {
        tr: require("../resources/locale/tr/elements_text.json"),
        tr_flag: require("../resources/locale/flags/tr.svg"),
        en: require("../resources/locale/en/elements_text.json"),
        en_flag: require("../resources/locale/flags/en.svg"),
      },
      language: "en",
      modalkey: 0,
    };
  },
  computed: {
    homepage() {
      return this.$route.name === "home";
    },
    localization() {
      return this.loc;
    },
  },
  props: {
    mode: String,
  },
  methods: {
    changeLanguage(lang) {
      //#region Elements
      const objectsById = {};

      for (const obj1 of ELEMENTS) {
        objectsById[obj1.number] = obj1;
      }

      const LANGUAGE_FILE = this.elementLanguage[lang];

      for (const obj2 of LANGUAGE_FILE) {
        const id = obj2.number;

        if (objectsById[id]) {
          objectsById[id] = _.merge(objectsById[id], obj2);
        } else {
          objectsById[id] = obj2;
        }
      }

      this.elements = Object.values(objectsById);
      this.currentElement = ELEMENTS[118];
      //#endregion

      //#region Content
      this.loc = this.locale[lang];
    },
    flagDisplay(lang) {
      lang = lang === "tr" ? "en" : "tr";
      document.querySelector(
        "#mmenu_langSwitch"
      ).innerHTML = `<img id="mmenu_langSwitch_flag" src="${
        this.elementLanguage[lang + "_flag"]
      }" alt="${lang} Flag">`;
    },
    languageSwitch() {
      if (this.language === "en") {
        this.language = "tr";
        this.flagDisplay("tr");
        this.changeLanguage("tr");
        // document.querySelector('#langmenu_list').innerHTML = `<img style="width: 1.5rem;" src="${this.elementLanguage[this.language + '_flag']}" alt="${this.language} Flag">` + " Türkçe"
        return;
      } else {
        this.language = "en";
        this.flagDisplay("en");
        this.changeLanguage("en");
        // document.querySelector('#langmenu_list').innerHTML = `<img style="width: 1.5rem;" src="${this.elementLanguage[this.language + '_flag']}" alt="${this.language} Flag">` + " English"
        return;
      }
    },
    getUserLanguage() {
      this.language = navigator.language.split("-")[0];
      // document.querySelector('#mmenu_langSwitch').innerHTML = `<img src="${this.elementLanguage[this.language + '_flag']}" alt="${this.language} Flag">`

      this.flagDisplay(this.language);
      this.changeLanguage(this.language);
      return this.language;
    },
    setElement(el) {
      this.modalViewable = !this.modalViewable;

      this.currentElement = el;
    },
    toggleModal($event) {
      const eventTarget = $event.target;

      if (
        eventTarget.classList.contains("close-modal") ||
        eventTarget.classList.contains("overlay")
      ) {
        // document.body.classList.remove('active_modal');
        document.body.removeAttribute("class");

        //Arama kutusunu temizle
        document.querySelector("#modalSearch").value = "";

        this.modalkey++;

        this.modalViewable = false;
        return;
      }
      if (!eventTarget.closest(".block")) return;

      this.modalViewable = !this.modalViewable;
    },
    // pageChange(state) {
    //   this.appView = state
    // },
    toggleTablePanel() {
      document.querySelector(".table_tabs").classList.remove("inactive");
      document.querySelector(".modules").classList.remove("inactive");
      document.querySelector("#tablePanelBtn").classList.add("inactive");
    },
    visitedBefore() {
      return localStorage.getItem("visited_before");
    },
  },
  mounted() {
    this.getUserLanguage();
  },
};
</script>

<style lang="scss" scoped>
.periodumLogoPart {
  fill: #ffce03;
}
.eaLogoPart {
  fill: #ffffff;
  pointer-events: none;
}
.eaLogoBackPart {
  fill: #1a1f2a;
}

#mmenu_langSwitch {
  min-height: 2.5rem;
  min-width: 2.5rem;
  align-items: center;
}
.flags {
  img {
    height: 2vw;
    min-width: 1.2vw;
    margin-right: 1vw;
  }
}

#tablemode {
  display: block;
  height: fit-content;

  @media screen and (max-width: 720px) {
    display: none;
  }
}
#listmode {
  display: none;

  @media screen and (max-width: 720px) {
    display: block;
    width: 90vw;

    padding-bottom: 30vw;
  }
}

.margin {
  margin-bottom: 5vw;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5vw;
  margin-top: 1rem;

  align-items: center;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    margin-top: 1rem;
    padding-bottom: 0.5rem;
  }
}

span {
  font-size: 4rem;
}

.title {
  color: white;
}

.navLeft {
  img {
    @media screen and (max-width: 720px) {
      max-width: 17rem;
    }
  }
  // &:hover {
  //   img {
  //     filter: grayscale(1) brightness(5);
  //   }
  // }
}
.navRight {
  border-radius: 0.5rem;
  right: 0;

  @media screen and (max-width: 720px) {
    padding: 1rem 0;
  }
}
.navIcon {
  color: #e5bb09;
  font-size: 1rem;
  margin: 0 0.2rem;
  transition: all 100ms ease-in-out;

  background-color: #1a1f2a;
  border: 1px solid #232a38;
  border-radius: 0.3rem;

  padding: 0.5rem 0.6rem;
  &:hover {
    color: white;
  }
}

.navIcon:hover {
  cursor: pointer;
}

#tablePanelBtn {
  animation: tilt-shaking 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.active {
  color: whitesmoke;
  display: block;
}
.inactive {
  display: none;
}

footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .social {
    margin: 0 0.3rem;
    color: white;
    padding: 1rem;
    background-color: #232a38;
    border-radius: 0.3rem;
    img {
      width: 24px;
      height: 24px;
      float: left;
      // margin-right: 1rem;
    }
    &:active {
      opacity: 1;
      border: 1px dashed black;
      word-break: break-all;
    }
  }
  margin: 2rem 0;
}
</style>
