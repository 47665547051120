export default {
  Da: [
    {
      target_unit: 'gr',
      formula: 'unit => unit*1.6605300000013E-24'
    },
    {
      target_unit: 'akb',
      formula: 'unit => unit*0.9999938574'
    },
    {
      target_unit: 'kg',
      formula: 'unit => unit*1.6605300000013E-27'
    },
    {
      target_unit: 'ton',
      formula: 'unit => unit*1.6605300000013E-30'
    },
    {
      target_unit: 'kısa ton',
      formula: 'unit => unit*1.8304210011307E-30'
    },
    {
      target_unit: 'uzun ton',
      formula: 'unit => unit*1.6343044652953E-30'
    },
    {
      target_unit: 'libre',
      formula: 'unit => unit*3.6608420022615E-27'
    },
    {
      target_unit: 'ons',
      formula: 'unit => unit*5.8573472036184E-26'
    },
    {
      target_unit: 'ag',
      formula: 'unit => unit*1.6605300000013E-06'
    },
    {
      target_unit: 'fg',
      formula: 'unit => unit*1.6605300000013E-09'
    },
    {
      target_unit: 'pg',
      formula: 'unit => unit*1.6605300000013E-12'
    },
    {
      target_unit: 'ng',
      formula: 'unit => unit*1.6605300000013E-15'
    },
    {
      target_unit: 'µg',
      formula: 'unit => unit*1.6605300000013E-18'
    },
    {
      target_unit: 'cg',
      formula: 'unit => unit*1.6605300000013E-22'
    },
    {
      target_unit: 'dg',
      formula: 'unit => unit*1.6605300000013E-23'
    },
    {
      target_unit: 'dag',
      formula: 'unit => unit*1.6605300000013E-25'
    },
    {
      target_unit: 'hg',
      formula: 'unit => unit*1.6605300000013E-26'
    },
    {
      target_unit: 'Mg',
      formula: 'unit => unit*1.6605300000013E-30'
    },
    {
      target_unit: 'Gg',
      formula: 'unit => unit*1.6605300000013E-33'
    },
    {
      target_unit: 'Tg',
      formula: 'unit => unit*1.6605300000013E-36'
    },
    {
      target_unit: 'Pg',
      formula: 'unit => unit*1.6605300000013E-39'
    },
    {
      target_unit: 'Eg',
      formula: 'unit => unit*1.6605300000013E-42'
    }
  ],
  pm: [
    {
      target_unit: 'A',
      formula: 'unit => unit*0.01'
    },
    {
      target_unit: 'am',
      formula: 'unit => unit*1000000'
    },
    {
      target_unit: 'fm',
      formula: 'unit => unit*1000'
    },
    {
      target_unit: 'µm',
      formula: 'unit => unit*1.00E-06'
    },
    {
      target_unit: 'nm',
      formula: 'unit => unit*0.001'
    },
    {
      target_unit: 'mm',
      formula: 'unit => unit*1.00E-09'
    },
    {
      target_unit: 'cm',
      formula: 'unit => unit*1.00E-10'
    },
    {
      target_unit: 'dm',
      formula: 'unit => unit*1.00E-11'
    },
    {
      target_unit: 'dam',
      formula: 'unit => unit*1.00E-13'
    },
    {
      target_unit: 'hm',
      formula: 'unit => unit*1.00E-14'
    },
    {
      target_unit: 'km',
      formula: 'unit => unit*1.00E-15'
    },
    {
      target_unit: 'Mm',
      formula: 'unit => unit*1.00E-18'
    },
    {
      target_unit: 'Gm',
      formula: 'unit => unit*1.00E-21'
    },
    {
      target_unit: 'Tm',
      formula: 'unit => unit*1.00E-24'
    },
    {
      target_unit: 'Pm',
      formula: 'unit => unit*1.00E-27'
    },
    {
      target_unit: 'Em',
      formula: 'unit => unit*1.00E-30'
    },
    {
      target_unit: 'in',
      formula: 'unit => unit*3.9370078740157E-11'
    },
    {
      target_unit: 'yd',
      formula: 'unit => unit*1.0936132983377E-12'
    },
    {
      target_unit: 'mi',
      formula: 'unit => unit*6.2137119223733E-16'
    },
    {
      target_unit: 'ft',
      formula: 'unit => unit*3.2808398950131E-12'
    },
    {
      target_unit: 'ly',
      formula: 'unit => unit*1.0570008340247E-28'
    },
    {
      target_unit: 'Planck',
      formula: 'unit => unit*6.1879273537329E+22'
    }
  ],
  K: [
    {
      target_unit: '°C',
      formula: 'unit => unit-273.15'
    },
    {
      target_unit: '°F',
      formula: 'unit => 9/5*unit-459.67'
    },
    {
      target_unit: '°R',
      formula: 'unit => unit*1.8'
    },
    {
      target_unit: '°r',
      formula: 'unit => (unit-273.15)*0.8'
    }
  ],
  MPa: [
    {
      target_unit: 'Pa',
      formula: 'unit => unit*1000000'
    },
    {
      target_unit: 'kPa',
      formula: 'unit => unit*1000'
    },
    {
      target_unit: 'bar',
      formula: 'unit => unit*10'
    },
    {
      target_unit: 'psi',
      formula: 'unit => unit*145.0377377'
    },
    {
      target_unit: 'ksi',
      formula: 'unit => unit*0.1450377377'
    },
    {
      target_unit: 'atm',
      formula: 'unit => unit*9.869232667'
    },
    {
      target_unit: 'aPa',
      formula: 'unit => unit*1.00E+24'
    },
    {
      target_unit: 'fPa',
      formula: 'unit => unit*1.00E+21'
    },
    {
      target_unit: 'pPa',
      formula: 'unit => unit*1.00E+18'
    },
    {
      target_unit: 'nPa',
      formula: 'unit => unit*1.00E+15'
    },
    {
      target_unit: 'µPa',
      formula: 'unit => unit*1000000000000'
    },
    {
      target_unit: 'mPa',
      formula: 'unit => unit*1000000000'
    },
    {
      target_unit: 'cPa',
      formula: 'unit => unit*100000000'
    },
    {
      target_unit: 'dPa',
      formula: 'unit => unit*10000000'
    },
    {
      target_unit: 'daPa',
      formula: 'unit => unit*100000'
    },
    {
      target_unit: 'hPa',
      formula: 'unit => unit*10000'
    },
    {
      target_unit: 'GPa',
      formula: 'unit => unit*0.001'
    },
    {
      target_unit: 'TPa',
      formula: 'unit => unit*1.00E-06'
    },
    {
      target_unit: 'PPa',
      formula: 'unit => unit*1.00E-09'
    },
    {
      target_unit: 'EPa',
      formula: 'unit => unit*1.0E-12'
    }
  ],
  'g/cm³': [
    {
      target_unit: 'kg/m³',
      formula: 'unit => unit*0.001'
    },
    {
      target_unit: 'kg/cm³',
      formula: 'unit => unit*1.00E-09'
    }
  ],
  'kg/m³': [
    {
      target_unit: 'g/cm³',
      formula: 'unit => unit*0.001'
    },
    {
      target_unit: 'kg/cm³',
      formula: 'unit => unit*1.00E-06'
    }
  ],
  cm3: [
    {
      target_unit: 'L',
      formula: 'unit => unit*0.001'
    },
    {
      target_unit: 'mm³',
      formula: 'unit => unit*1000'
    }
  ],
  'kJ/mol': [
    {
      target_unit: 'eV',
      formula: 'unit => unit*0.0103641'
    },
    {
      target_unit: 'kcal/mol',
      formula: 'unit => unit*0.239001'
    }
  ],
  'm/s': [
    {
      target_unit: 'km/h',
      formula: 'unit => unit*3.6'
    },
    {
      target_unit: 'mph',
      formula: 'unit => unit*2.236936292'
    },
    {
      target_unit: 'c',
      formula: 'unit => unit*3.3356409519815E-09'
    }
  ]
}
